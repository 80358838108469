export const getProperties={
    GET_ALL_PROPERTY:"GET_ALL_PROPERTY",
    EMPTY_PROPERTIES:"EMPTY_PROPERTIES"
}

export const house ={
    FETCH_HOUSE:"FETCH_HOUSE",
    EMPTY_HOUSE:"EMPTY_HOUSE"
}
export const switchState = {
    SWITCH_ON:"SWITCH_ON",
    SWITCH_OFF:"SWITCH_OFF"
}
export const userDetail={
    SAVE_lOGIN_DETAILS:"SAVE_lOGIN_DETAILS"
}
export const logs = {
    TODAY_GET_LOGS:"TODAY_GET_LOGS",
    EMPTY_TODAY_LOGS:"EMPTY_TODAY_LOGS"
}
export const schedule = {
    ADD_SCHEDULE:"ADD_SCHEDULE",
    FETCH_SCHEDULE:"FETCH_SCHEDULE",
    EMPTY_SCHEDULE:"EMPTY_SCHEDULE",
    REMOVE_SCHEDULE:"REMOVE_SCHEDULE"
}