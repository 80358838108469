import React from 'react'

export default function StatusBox({backgroundColor,status,number,circleColor}) {
    return (
        <div
            style={{
                width:"152px",
                height:"132px",
                backgroundColor: backgroundColor,
                borderRadius: "10.2519px",
                paddingLeft: "11.4px",
                paddingBottom:"10px",
                paddingRight:"5px",
            
            }}
        >
            <div
                style={{
                    width: "36.91px",
                    height: "36.91px",
                    marginTop:"14.35px",
                    backgroundColor: circleColor,
                    borderRadius: "18.4535px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <div style={{
                    width: "16.4px",
                    height: "16.4px",
                    backgroundColor: "#FFFFFF",
                }}>

                </div>

            </div>
            <div
                style={{
                    fontFamily: 'Manrope',
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "20.5835px",
                    lineHeight: "31px",
                    color: "#141D48",
                    textAlign:"left",
                    marginTop:"9.23px"
                }}
            >{number}</div>

            <div style={{
                fontFamily: 'Manrope',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14.5065px",
                lineHeight: "22px",
                color: "#46556A",
                textAlign:"left"
            }}>
                {status}

            </div>
           
        </div>
    )
}
