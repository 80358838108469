import { Button, Stack } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { SlashCircle } from 'react-bootstrap-icons'
import './TableData.css'
import { useDispatch, useSelector } from 'react-redux';
import OnTimeRow from './OnTimeRow';
import { TurnOffAllApi, TurnOnAllApi, deviceBlock } from '../../Redux/Action/PropertyAction';
import RoomBlockUnBlock from './RoomBlockUnBlock';
import { useEffect } from 'react';


function TableData({notifySuccess,notifyFailed}) {
  let HouseDetails = useSelector(state=>state.HouseDetails)
  const dispatch = useDispatch()
  console.log(HouseDetails,"house");
  useEffect(()=>{},[HouseDetails])
  return (
    <Table hover style={{ textAlign: "left", marginLeft: "30px", marginTop: "15px", width: "96%", borderRadius: "12px", overflow: "hidden" }}>

      <thead>
        <tr style={{ width: "100%" }}>
          <th colSpan={"5"} style={{
            borderBottom: 0, fontFamily: 'Manrope',
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "20.4067px",
            lineHeight: "31px",
            color: "#000000"
          }}>Room wise breakdown</th>
        </tr>
        <tr>
          <th className='tableHeading'>#</th>
          <th className='tableHeading'>Room Name</th>
          <th className='tableHeading'>Occupancy Hours</th>
          <th className='tableHeading'>Current Status</th>
          <th className='tableHeading'>Room Status</th>
          <th className='tableHeading'>Action</th>
        </tr>
      </thead>
      <tbody>
        {
          HouseDetails.length!==0?
          HouseDetails.map(house=>{
            console.log(house,"qqqq");
            return(
                 house.rooms.map((room,index)=>(
         <tr key={room._id}>
          <td className='tableData'>{index+1}</td>
          <td className='tableData'>{room.roomName}</td>
          <OnTimeRow device={room.devices}/>
          <RoomBlockUnBlock device={room.devices}/>
          <td>
            <Stack direction="horizontal" gap={3}>
            <Button variant="outline-secondary" size='sm' style={{
            display: "flex",
            alignItems: "center"
          }}
          onClick={()=>TurnOnAllApi(dispatch,room.devices,house.house._id)}
          ><SlashCircle style={{ marginRight: "5px" }} />Turn On</Button>
            <Button variant="outline-secondary" size='sm' style={{
            display: "flex",
            alignItems: "center"
          }}
          onClick={()=>TurnOffAllApi(dispatch,room.devices,house.house._id)}
          ><SlashCircle style={{ marginRight: "5px" }} />Turn Off</Button>
          <Button variant="outline-secondary" size='sm' style={{
            display: "flex",
            alignItems: "center"
          }}
          onClick={()=>deviceBlock(dispatch,room.devices,notifySuccess,notifyFailed,1,`${house.house._id}-${house.house.houseName}`)}
          ><SlashCircle style={{ marginRight: "5px" }} />UnBlock manual switches</Button>
          <Button variant="outline-secondary" size='sm' style={{
            display: "flex",
            alignItems: "center"
          }}
          onClick={()=>deviceBlock(dispatch,room.devices,notifySuccess,notifyFailed,0,`${house.house._id}-${house.house.houseName}`)}
          ><SlashCircle style={{ marginRight: "5px" }} />Block manual switches</Button>
          </Stack>
          </td>
        </tr>
                 ))
          )}):null
        }
        
      
      </tbody>
    </Table>
  );
}

export default TableData;