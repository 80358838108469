import axios, { all } from "axios"
import { serverUrl } from "../../../ApiUri"
import { getProperties, house, switchState } from "./ActionTypes"

const getProperty = (data)=>{
    return{
        type:getProperties.GET_ALL_PROPERTY,
        payload:data
    }
}
export const emptyProperty = (data)=>{
    return{
        type:getProperties.EMPTY_PROPERTIES,
        payload:data
    }
}
const houseData = (data)=>{
    return{
        type:house.FETCH_HOUSE,
        payload:data
    }
}
const allHouseData = (data)=>{
    return{
        type:house.FETCH_HOUSE,
        payload:data
    }
}
export const houseDataEmpty = (data)=>{
    return{
        type:house.EMPTY_HOUSE,
        payload:data
    }
}

export const TurnOn = (data)=>{
    return{
        type:switchState.SWITCH_ON,
        payload:data
    }
}
export const TurnOff = (data)=>{
    return{
        type:switchState.SWITCH_ON,
        payload:data
    }
}



export const getPropertiesApi=(dispatch)=>{
  const spipProperty = ['651bbc61c31ac4f0ad4edc30',"651bbc61c31ac4f0ad4edc2c"]
    axios.post(`${serverUrl.main}/api/cmp/getProperty`,{
     companyCode:"1fbf12"
    }).then(function(res){
     
        
        if(res.status===200){
          let data = res.data.property.filter(p=>{
            if(!spipProperty.includes(p._id)){
              return p
            }
          })
            dispatch(getProperty({property:data,success:true,message:"Property Fetch"}))
           
        }else{
            // dispatch(getProperty({property:[],success:false,message:"Property Not Fetch"}))
        }
        

    }).catch(function (err) {
        console.log(err);
        // dispatch(getProperty({property:[],success:false,message:"Property Not Fetch"}))

    })
    //        obj= {success:false,message:"House Not Fetch",rooms:[],propertyName:undefined,house:undefined}
    // dispatch(getProperty({property:[{houseId:"63132abaae9c2f40e7f573f2",propertyName:"Aliste HeadQuater"}],success:true,message:"Property Fetch"}))
 }


export const fetchHouse = async(property,dispatch)=>{
       property=property.split('-')
       let house = []
      
     await axios
        .get(
          `${serverUrl.main}/api/fetch/house2/${property[0]}/${
           "+918595679518"
          }?user=${"+918595679518"}`,
        ).then(function (res) {
            console.log(res,"fet");
            if(res.status===200){
               let obj ={success:true,message:"House Fetch",rooms:res.data.rooms,propertyName:property[1],house:res.data}
                house.push(obj)
            }else{
    
            }
        }).catch(function (error) {
            console.log(error);
    
        })
     
      
      dispatch(allHouseData(house))
}

const sendSocketMessage = (deviceIds, event, payload) => {
    let deviceType="";
        if(deviceIds.substring(1,3)=="08"){
           deviceType="nova"
        }else{
          deviceType="sync"
        }
	return new Promise(resolve => {
    axios
      .post(`https://a3.alistetechnologies.com/v2/admin/socket_message?invoker=stanzaDashBoard`, {
        deviceType,
        deviceIds:[deviceIds],
        event,
        payload,
      })
      .then(
        result => {
          resolve({success: true, data: result.data, message: ''});
        },
        error => {
          let message = error.message;
          resolve({success: false, message});
        },
      )
      .catch(error => {
        resolve({success: false, message: String(error)});
      });
  });
} 

export const TurnOnApi = (dispatch,sswitch,devicesId,houseId)=>{
     
     let newSwitch = {...sswitch,switchState:"1"}
     dispatch(TurnOn({switch:newSwitch,devicesId:devicesId,houseId:houseId}))
     sendSocketMessage(devicesId,"chat message",`${sswitch.switchId},${1},${String(new Date().getTime()).slice(5, 13)}`)
}

export const TurnOffApi = (dispatch,sswitch,devicesId,houseId)=>{
    
    let newSwitch = {...sswitch,switchState:"0"}
    dispatch(TurnOn({switch:newSwitch,devicesId:devicesId,houseId:houseId}))
    sendSocketMessage(devicesId,"chat message",`${sswitch.switchId},${0},${String(new Date().getTime()).slice(5, 13)}`)

}

export const TurnOnAllApi = (dispatch,devices,houseId)=>{
    for(let device of devices){
       for(let swit of device.switches){
        let newSwitch ={...swit,switchState:"1"}
        dispatch(TurnOn({switch:newSwitch,devicesId:device.deviceId,houseId:houseId}))
        sendSocketMessage(device.deviceId,"chat message",`${newSwitch.switchId},${1},${String(new Date().getTime()).slice(5, 13)}`)
       }
    }
}

export const TurnOffAllApi = (dispatch,devices,houseId)=>{
    console.log(houseId,"off");
  for(let device of devices){
    for(let swit of device.switches){
     let newSwitch ={...swit,switchState:"0"}
     dispatch(TurnOff({switch:newSwitch,devicesId:device.deviceId,houseId:houseId}))
     sendSocketMessage(device.deviceId,"chat message",`${newSwitch.switchId},${0},${String(new Date().getTime()).slice(5, 13)}`)
    }
 }
}

export const SelctedSwitchMannualBlock=async(productDataItem,setProductDataItem,user,notifySuccess,event)=>{
    
    for(let swit of productDataItem){
        let deviceType="";
        if(swit.deviceId.substring(1,3)=="08"){
           deviceType="nova"
        }else{
          deviceType="sync"
        }
        await axios.post(`https://a3.alistetechnologies.com/v2/admin/socket_message?user=${user.username}&username=${user.username}&timestamp=${new Date()}`,{
              deviceType: deviceType,
              deviceIds: [swit.deviceId],
              event: 'switch_pins',
              payload: `${swit.switchId},${event},${String(new Date().getTime()).slice(5,13)}`,
             }).then(function (res) {
                
             }).catch(function (err) {
                console.log(err);
             })
    }
    if(event===0){
    notifySuccess("Switches Bloacked Sucessfully")
    }else if(event===1){
        notifySuccess("Switches UnBloacked Sucessfully") 
    }
    setProductDataItem([])
   
}

export const deviceBlock = async(dispatch,devices,notifySuccess,notifyFailed,decision,id)=>{
       for(let item of devices){
        let event = ""
        if(decision===1){
            if(item.deviceId.substring(1,3)=="01"){
              event="1"
           }else if(item.deviceId.substring(1,3)=="02"){
             event="11"
           }else if(item.deviceId.substring(1,3)=="03"){
             event="1111"
           }else if(item.deviceId.substring(1,3)=="04"){
             event="11111"
           }else if(item.deviceId.substring(1,3)=="05"){
             event="11111"
           }else if(item.deviceId.substring(1,3)=="06"){
             event="11"
           }else if(item.deviceId.substring(1,3)=="12"){
             event="111"
           }else if(item.deviceId.substring(1,3)=="13"){
             event="1111"
           }else{
            continue;
           }
          }else if(decision===0){
            if(item.deviceId.substring(1,3)=="01"){
              event="0"
           }else if(item.deviceId.substring(1,3)=="02"){
             event="00"
           }else if(item.deviceId.substring(1,3)=="03"){
             event="0000"
           }else if(item.deviceId.substring(1,3)=="04"){
             event="00000"
           }else if(item.deviceId.substring(1,3)=="05"){
             event="00000"
           }else if(item.deviceId.substring(1,3)=="06"){
             event="00"
           }else if(item.deviceId.substring(1,3)=="12"){
             event="000"
           }else if(item.deviceId.substring(1,3)=="13"){
             event="0000"
           }else{
            continue;
           }
          }
          axios.post(`${serverUrl.a3}/v3/device/updateSwitchStates?user=+918708923677`,{
            deviceId: item.deviceId,
            states: event
          }).then(function (res) {
              
          }).catch(function (error) {
            console.log(error);
          })
       }
       if(decision===0){
        notifySuccess("Switches Bloacked Sucessfully")
        }else if(decision===1){
            notifySuccess("Switches UnBloacked Sucessfully")
        }
        console.log(id);
        fetchHouse(id,dispatch)
}