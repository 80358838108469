import React, { useEffect } from 'react'
import { Col,Row } from 'react-bootstrap'
import { BoxArrowLeft } from 'react-bootstrap-icons'
import { useDispatch, useSelector } from 'react-redux'
import { loginDetailSave } from '../Redux/Action/UserDetail'
import { useNavigate } from 'react-router-dom'
import { houseDataEmpty } from '../Redux/Action/PropertyAction'

export default function SideBar() {
  const User = useSelector(state=>state.User)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(()=>{
    if(Object.keys(User).length===0){
    navigate('/')
    }
  },[User])
  return (
    <div style={{width:"6%",height:"100vh",backgroundColor:"#FFFFFF"}}>
       <div style={{height:"10vh",display:"flex",alignItems:"center",justifyContent:"center"}}>
            <img style={{height:"29.78px",width:"31.91px"}} src={process.env.PUBLIC_URL+'./assets/Img/Logo.png'}/>
       </div>
       <div style={{height:"10vh",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",cursor:"pointer"}}>
            <img style={{height:"25.11px",width:"28.83px"}} src={process.env.PUBLIC_URL+'./assets/Img/Home.png'}/>
            <span style={{fontSize: "8.76585px",lineHeight: "13px"}}>Home</span>
       </div>
       <div style={{height:"10vh",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",cursor:"pointer",}}
         onClick={()=>{dispatch(loginDetailSave({}))
              dispatch(houseDataEmpty([]))}}
       >
        <BoxArrowLeft style={{fontSize:"28px"}}/>
       </div>

    </div>
  )
}
