import { schedule } from "../Action/ActionTypes";

export const fetchScheduleReducer = (state=[],action)=>{
    switch (action.type) {
        case schedule.FETCH_SCHEDULE:
            return action.payload
        case schedule.EMPTY_SCHEDULE:
            return action.payload
        case schedule.REMOVE_SCHEDULE:
             let sch = [...state]
              sch=  state.map(schedule=>{
                   let newSchedule = {...schedule}
                    if(schedule.schedule.length!==0){
                         newSchedule = schedule.schedule.filter((ss)=>{
                           
                            if (ss._id!==action.payload) {
                                return ss
                            }
                         })
                         return {...schedule,schedule:newSchedule}
                    }else{
                        return schedule
                    }
                    
                 })

             return sch
        default:
            return state
    }
}