import { createStore,combineReducers } from "redux";
import { PropertyDetails,HouseDetails } from "./Reducers/PropertyReducer";
import { userDetailReducer } from "./Reducers/UserReducer";
import {persistStore,persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage'
import { toadayLogReducer } from "./Reducers/logsReducer";
import { fetchScheduleReducer } from "./Reducers/ScheduleReducer";

const persistConfig = {
    key: 'persist-key',
    storage
}

const persistedReducer = persistReducer(persistConfig,combineReducers({
    PropertyDetails,
    HouseDetails,
    User:userDetailReducer,
    todayLogs:toadayLogReducer,
    schedule:fetchScheduleReducer,
}))

let store = createStore(persistedReducer);
let persistor = persistStore(store)
export default store;
export {persistor}