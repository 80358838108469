import React from 'react'
import "./Header.css"
import { useSelector } from 'react-redux'

export default function Header() {
   let User = useSelector(state=>state.User)
  return (
    <div 
      style={{
        width:"100%",
        height:"10vh",
        backgroundColor:"#FFFFFF",
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        padding:"0px 5%"
      }}
    >
        <div>
            <span className='clientHeader'>Hive </span>
            <span className='clientSubHeader'>Hostels</span>
        </div>
        <div style={{
            display:"flex"
        }}>
            <div>
                {/* <img 
                  src={process.env.PUBLIC_URL+'./assets/Img/box.png'} 
                  style={{
                    width: "36.87px",
                    height: "36.87px"
                  }}
                /> */}
            </div>
            <div
             style={{
                display:"flex",
                flexDirection:"column",
                marginLeft:"10px",
                justifyContent: "center"
             }}
            >
                <span className='userName'>{User.userName}</span>
                {/* <span className='admin'>admin</span> */}
            </div>
        </div>

    </div>
  )
}
