import React,{useState} from 'react'
import { Container,Form,Row,Col,Button,InputGroup } from 'react-bootstrap'
import { FolderMinus } from 'react-bootstrap-icons';
import { redirect, useNavigate } from 'react-router-dom';
import { getPropertiesApi, houseDataEmpty } from '../Redux/Action/PropertyAction';
import { useDispatch } from 'react-redux';
import { loginDetailSave } from '../Redux/Action/UserDetail';
import toast, { Toaster } from 'react-hot-toast'

export default function Login() {
    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const notifyFailed = (message) => toast.error(message)
    const handleSubmit = (event) => {
        
        event.preventDefault();
      const form = new FormData(event.currentTarget);

      let data = {
        userName:form.get('loginId'),
        password:form.get("password")
      }
      if(data.userName!=="Admin" || data.password!=="admin@123"){
           notifyFailed("Login Details Are Incorrect")
           return
      }
    dispatch(loginDetailSave(data))
    dispatch(houseDataEmpty([]))
      getPropertiesApi(dispatch)
       navigate('/DashBoard')
    };
  return (
    <Container style={{display:'flex',justifyContent:"center",height:"100vh",alignItems:"center"}}>
        <Toaster/>
        <div style={{width:"25%",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",padding:"2.5%",borderRadius:"12px"}}>
     
       <div>
        <img 
          style={{height:"50px",marginBottom:"10px"}}
          src={process.env.PUBLIC_URL+"./assets/Img/Logo.png"}/>
       </div>
       <form onSubmit={handleSubmit}>

       <Form.Group as={Col}  controlId="validationCustom03">
          <Form.Label>Login Id</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Login ID" 
            required 
            name="loginId"
            autoComplete='loginId'
          />
        </Form.Group>
        <Form.Group as={Col}  controlId="validationCustom03">
          <Form.Label>Password</Form.Label>
          <Form.Control 
            type="password" 
            required 
            name="password"
            autoComplete='current-password'
          />
        </Form.Group>
        <Button type='submit' className='mt-2'>login</Button>
       </form>
       
        </div>
    </Container>
  )
}
