import React, { useEffect, useState } from 'react'
import { Stack,Form } from 'react-bootstrap'
import './DashBoard.css'
import Table from './Common/TableData'
import StatusBox from './Common/StatusBox'
import { useDispatch, useSelector } from 'react-redux'
import { fetchHouse, getPropertiesApi, houseDataEmpty } from '../Redux/Action/PropertyAction'
import toast, { Toaster } from 'react-hot-toast'
import axios from 'axios'
import Spinner from './Spinner'

export default function DashBord() {
    let PropertyDetails = useSelector(state=>state.PropertyDetails)
    let HouseDetails = useSelector(state=>state.HouseDetails)
    const [room,setRoom] = useState({occupy:0,unoccupy:0,Blocked:0,room:0})
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()
    const notifySuccess = (message) => toast.success(message)
    const notifyFailed = (message) => toast.error(message)
    
    window.onbeforeunload = function () {
      console.log( 'Are you sure you want to leave?');
      dispatch(houseDataEmpty([]))
   }
    const todayLogOntime =async()=>{
     let occupancy =0;
     let unoccupy=0;
     let Blocked =0;
     setRoom({occupy:"Loading...",unoccupy:"Loading...",Blocked:"Loading...",room:"Loading..."})
    setLoading(false)
      for(let room of HouseDetails[0].rooms){
        let onTimeArray = []
        let un=0
        for(let d of room.devices){
          let ess = d.ess.split("")
          
          if(ess[0]==="0"){
             un=1
          }
          for(let s of d.switches){
          await axios.post(`https://analytics.alistetechnologies.com:443/analytic/v2/getDataForToday`,{
            // "deviceId" : d.deviceId,
            // "roomId" : "DFS",
            // "commands":false
            
              "dSIDs":[
                 {
                "deviceId": d.deviceId,
                 "switchId":s.switchId
                 },
                
              ]
              
            
        }).then(function (res) {
             if(res.data.results.finalResult.length!==0){
              let onTime = res.data.results.finalResult[0].ontime
              onTimeArray.push(onTime)
             }
         }).catch(function (err) {
             console.log(err);
   
         })
        }
        }
      
        if(un===1){
           Blocked +=1
        }
        onTimeArray = onTimeArray.sort(function (a, b) { return b - a })
     const durationInMilliseconds = onTimeArray[0];
  
      const seconds = Math.floor(durationInMilliseconds / 1000);
     const hours = Math.floor(seconds / (60 * 60));
     const minutes = Math.floor((seconds % 3600) / 60);
     
     const formattedTime = `${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m `;
     if(hours.toString().padStart(2, '0')>=1){
        occupancy +=1
     }else{
      unoccupy +=1
     }
      }
      
     setRoom({occupy:occupancy,unoccupy:unoccupy,Blocked:Blocked,room:HouseDetails[0].rooms.length})
     
   }
   useEffect(()=>{
    getPropertiesApi(dispatch)
   },[])
   console.log(PropertyDetails,"property");
   useEffect(()=>{
    
   if(HouseDetails.length===0 || HouseDetails[0].rooms.length===0) return
   todayLogOntime()
   },[HouseDetails])
  return (
    <div
      style={{width:"94vw",height:"90vh",backgroundColor:"#F9F9FB",overflow:"scroll"}}
      className='dashboard'
    >
      <Toaster/>
    <Stack direction="horizontal" gap={3} style={{paddingLeft:"30px",paddingTop:"30px"}}>
    <Form.Select style={{
       width:"22%",
       fontFamily: 'Manrope',
       fontStyle: "normal",
       fontWeight: "600",
       fontSize: "12.9008px",
       lineHeight: "19px",
       color: "#000000",
    }}
    onChange={(e)=>{
      if(e.target.value===""){
      dispatch(houseDataEmpty([]))
      setRoom({occupy:0,unoccupy:0,Blocked:0,room:0})
      }else{
        dispatch(houseDataEmpty([]))
        fetchHouse(e.target.value,dispatch)
        setLoading(true)
      }
    }}
    >
        <option value={""}>Default select</option>
        {
          (Object.keys(PropertyDetails).length!==0 && PropertyDetails.property.length!==0)?
          PropertyDetails.property.map((p)=>(
            <option value={`${p.houseId}-${p.propertyName}`}>{p.propertyName}</option>
          )):null
        }
      </Form.Select>
    <div className='roomNumber'>
        <span className='roomTotalHeader'>Total Room </span><span className='roomTotalNumber'>{room.room}</span>
    </div>
  
    </Stack>
    <Stack direction="horizontal" gap={3} className='mt-3' style={{paddingLeft:"30px"}}>
        <StatusBox backgroundColor={"#FFE2E6"} status={"Occupied Rooms "} number={room.occupy} circleColor={"#FA5A7C"}/>
        <StatusBox backgroundColor={"#FFF4DE"} status={"Empty Rooms"} number={room.unoccupy} circleColor={"#FE947A"}/>
        {/* <StatusBox backgroundColor={"#DCFCE7"} status={"Avg occupancy"} number={"2h 40m"} circleColor={"#3CD956"}/> */}
        <StatusBox backgroundColor={"#F4E8FF"} status={"Blocked Rooms"} number={room.Blocked} circleColor={"#BF83FD"}/>
    </Stack>
    {
      (loading && HouseDetails.length===0) ?<Spinner/>:<Table notifySuccess={notifySuccess} notifyFailed={notifyFailed}/>
    }
    {/* <Table notifySuccess={notifySuccess} notifyFailed={notifyFailed}/> */}
    </div>
  )
} 
