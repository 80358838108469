import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';

export default function OnTimeRow({device}) {
  console.log(device);
    let [onTime,setOntime] = useState("Loading...")
    let [occupancy,setOccupancy] = useState({state:"Loading...",decision:false})
    const todayLogOntime =async()=>{
      
         let onTimeArray = []
         for(let d of device){
        //   await axios.post(`https://analytics.alistetechnologies.com:443/analytic/v2/getDataForToday`,{
        //     // "deviceId" : d.deviceId,
        //     // "roomId" : "DFS",
        //     // "commands":false
        //     "dSIDs":[
        //       {
        //      "deviceId": d.deviceId,
        //       "switchId":s.switchId
        //       },
             
        //    ]
        // }).then(function (res) {
        //      if(res.data.final.length!==0){
        //       let filerSwitch = res.data.final
        //       filerSwitch.map(e=>onTimeArray.push(e.ontime))
        //      }
        //  }).catch(function (err) {
        //      console.log(err);

        //  })
         for(let s of d.switches){
          await axios.post(`https://analytics.alistetechnologies.com:443/analytic/v2/getDataForToday`,{
            // "deviceId" : d.deviceId,
            // "roomId" : "DFS",
            // "commands":false
            
              "dSIDs":[
                 {
                "deviceId": d.deviceId,
                 "switchId":s.switchId
                 },
                
              ]
              
            
        }).then(function (res) {
             if(res.data.results.finalResult.length!==0){
              let onTime = res.data.results.finalResult[0].ontime
              onTimeArray.push(onTime)
             }
         }).catch(function (err) {
             console.log(err);
   
         })
        }
        }
        
        onTimeArray = onTimeArray.sort(function (a, b) { return b - a })
        const durationInMilliseconds = onTimeArray[0];
     
         const seconds = Math.floor(durationInMilliseconds / 1000);
        const hours = Math.floor(seconds / (60 * 60));
        const minutes = Math.floor((seconds % 3600) / 60);
        
        const formattedTime = `${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m `;
        if(hours.toString().padStart(2, '0')>=1){
          setOccupancy({state:"Done",decision:true})
        }
        if(onTimeArray.length!==0 && onTimeArray[0]!==0){
        setOntime(formattedTime)
        if(hours.toString().padStart(2, '0')<1){
          setOccupancy({state:"NotDone",decision:false})
        }
        }else{
          setOntime('---')
          setOccupancy({...occupancy,state:"---"})
        }
      }
      useEffect(()=>{todayLogOntime()},[device])
  return (
    <>
    <td className='tableData'>{onTime}</td>
    <td>{occupancy.state==="Loading..."?<Button size='sm' style={{ backgroundColor: "rgba(189, 0, 0, 0.3)" }} variant="outline-danger">Loading...</Button>:((occupancy.state==="Done" && occupancy.decision===true)?<Button variant="outline-success" size='sm' style={{ backgroundColor: "rgba(115, 241, 191, 0.3)" }}>Occupied</Button>:(occupancy.state==="---"?<Button size='sm' style={{ backgroundColor: "rgba(189, 0, 0, 0.3)" }} variant="outline-danger">---</Button>:<Button size='sm' style={{ backgroundColor: "rgba(189, 0, 0, 0.3)" }} variant="outline-danger">Unoccupied</Button>))}</td>
    {/* <td>{occupancy ? <Button variant="outline-success" size='sm' style={{ backgroundColor: "rgba(115, 241, 191, 0.3)" }}>Occupied</Button> : <Button size='sm' style={{ backgroundColor: "rgba(189, 0, 0, 0.3)" }} variant="outline-danger">Unoccupied</Button>}</td> */}
    </>
  )
}
