import React from 'react'
import SideBar from '../DashBoard/SideBar'
import Header from '../DashBoard/Header'
import DashBord from '../DashBoard/DashBord'
import { Container,Row } from 'react-bootstrap'


export default function Main() {
  return (
    <div style={{width:"100%"}}>
      <div style={{display:"flex"}}>  
      
      <SideBar/>
      
      <div>
        <Header/>
        <DashBord/>
      </div>
      </div>
    </div>
  )
}
