
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export default function RoomBlockUnBlock({device}) {
    let [occupancy,setOccupancy] = useState(true)
    let HouseDetails = useSelector(state=>state.HouseDetails)

    const blockUnBlock =async()=>{
        setOccupancy(true)
        for(let d of device){
            let ess=d.ess.split("")
            if(ess[0]==="0"){
                setOccupancy(false)
                return
            }
        }
    }   
        
      
      useEffect(()=>{blockUnBlock()},[device,HouseDetails])
  return (
    <>
    <td>{occupancy ? <Button variant="outline-success" size='sm' style={{ backgroundColor: "rgba(115, 241, 191, 0.3)" }}>UnBlocked</Button> : <Button size='sm' style={{ backgroundColor: "rgba(189, 0, 0, 0.3)" }} variant="outline-danger">Blocked</Button>}</td>
    </>
  )
}
